import { useState, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { currentLangState } from 'atoms/projectData';

// 解析モードを変更するロジック
export const useChangeAnalyticeMode = (setOutputTypeState, t, analysisVer, debugFlag) => {

  const [AnalyticeModeType, setAnalyticeModeType] = useState('ground'); // 解析点の色分けの制御
  const [AnalyticeModeOpacityType, setAnalyticeModeOpacityType] = useState('none'); // 解析点の透明度の制御
  const [isSlider, setIsSlider] = useState(true); //ドロワーメニューの凡例スライダーの表示非表示を制御
  const [AnalyticeIsShowAoi, setAnalyticeIsShowAoi] = useState([]); // 解析ポイントが表示されているAOIのIDを格納
  const [usageGuideunit, setUsageGuideunit] = useState(t("map.year")) // ドロワーメニューの凡例単位を定義
  const [isAbsMode, setIsAbsMode] = useState(false); // 絶対値モードのスイッチフラグ

  //Recoilから取得
  const lang = useRecoilValue(currentLangState);

  // ...(debugFlag ? [

  // ] : [])
  // 解析点の色分けの選択可能項目を定義
  let ModeTypes;
  if(analysisVer >= 3.0){
    //analysis.geojsonのバージョンが3の場合
    ModeTypes = [
      {none: t("map.invalid")}, // 無効
      {ground: t("map.measuredValue")}, // 計測値
      ...(lang === "ja" ? [{ slope: t("map.rankPeriod") }] : []), // ランク(直近)
      ...(lang === "ja" ? [{ slopePeriod: t("map.rankPeriod") }] : []), // ランク(直近)
      {gDirection: t("map.directionOfSlope")}, // 勾配の方向
      {gSizeDirection: t("map.slopeDirectonSize")}, // 勾配の角度(Dev環境のみ選択可能)
      {cReliability: t("map.reliability")}, // 信頼度
    ];
  }else if(analysisVer >= 2.0){
    //analysis.geojsonのバージョンが2の場合
    ModeTypes = [
      {none: t("map.invalid")}, // 無効
      {ground: t("map.measuredValue")}, // 計測値
      ...(lang === "ja" ? [{ slope: t("map.rankPeriod") }] : []), // ランク(直近)
      ...(lang === "ja" ? [{ slopePeriod: t("map.rankPeriod") }] : []), // ランク(直近)
      {gDirection: t("map.directionOfSlope")}, // 勾配の向き
      {gSizeDirection: t("map.slopeDirectonSize")}, // 勾配(大きさ)Dev環境のみ選択可能
    ];
  }else if(analysisVer >= 1.0){
    //analysis.geojsonのバージョンが1の場合
    ModeTypes = [
      {none: t("map.invalid")}, // 無効
      {ground: t("map.measuredValue")}, //　計測値
      {slope: t("map.rank")}, // ランク(全期間)
      {slopePeriod: t("map.rankPeriod")}, // ランク(直近)
    ];
  }

  // 解析点の透明度の選択可能項目を定義
  let opacityTypes;
  if(analysisVer >= 3.0){
    //analysis.geojsonのバージョンが3の場合
    opacityTypes = [
      {none: t("map.invalid")}, // 無効
      {ground: t("map.measuredValue")}, // 計測値
      {gSizeDirection: t("map.slopeDirectonSize")}, // 勾配(大きさ)Dev環境のみ選択可能
      {cReliability: t("map.reliability")}, // 信頼度
    ];
  }else if(analysisVer >= 2.0){
    //analysis.geojsonのバージョンが2の場合
    opacityTypes = [
      {none: t("map.invalid")}, // 無効
      {ground: t("map.measuredValue")}, // 計測値
      {gSizeDirection: t("map.slopeDirectonSize")}, // 勾配(大きさ)Dev環境のみ選択可能
    ];
  }else if(analysisVer >= 1.0){
    //analysis.geojsonのバージョンが1の場合
    opacityTypes = [
      {none: t("map.invalid")}, // 無効
      {ground: t("map.measuredValue")}, //　計測値
    ];
  }
    
  // 解析点の色分け選択時の処理
  const handleChangeAnalyticeMode = (event) => {
    const mode = event.target.value;
    setAnalyticeModeType(mode);

    // 凡例スライダーの非活性の制御
    if(mode === 'ground'){
      setIsSlider(true);
    }else{
      setIsSlider(false);
    }

    if(event.target.value === 'ground') {
      setUsageGuideunit(t("map.year"));
    };
    if(event.target.value === 'slope') {
      setUsageGuideunit(t("map.month"));
      setOutputTypeState('velocity');
    };
  }
  
  // 解析点の透明度選択時の処理
  const handleChangeAnalysisOpacity = useCallback((event) => {
    const opacityMode = event.target.value;
    setAnalyticeModeOpacityType(opacityMode);
  },[])

  // 絶対値モード用のスイッチの状態変更処理
  const handleChangeAbsMode = useCallback((e) => {
    setIsAbsMode(e.target.checked);
  },[])

  /**
   * 解析点の色を濃くする処理
   * @param {*} baseColorCode 色コード16進数(#fffff)
   * @param {*} darkWeight 濃くする度合い(0.0 ~ 1.0)
   * @returns カラーコード 色コード16進数(#fffff)
   */
  const getDarkColor = (baseColorCode, darkWeight) => {
    // 10進数に変換
    let red = parseInt(baseColorCode.substring(1, 3), 16);
    let green = parseInt(baseColorCode.substring(3, 5), 16);
    let blue = parseInt(baseColorCode.substring(5, 7), 16);
    // 少し濃くする
    let redNew = Math.round(red * (1 - darkWeight));
    let greenNew = Math.round(green * (1 - darkWeight));
    let blueNew = Math.round(blue * (1 - darkWeight));
    // 16進数にに再変換
    let rHex = redNew.toString(16);
    rHex = ('00' + rHex).slice(-2);
    let gHex = greenNew.toString(16);
    gHex = ('00' + gHex).slice(-2);
    let bHex = blueNew.toString(16);
    bHex = ('00' + bHex).slice(-2);
    
    return '#' + rHex + gHex + bHex;
  }

  return {
    AnalyticeModeType,
    isSlider,
    AnalyticeIsShowAoi,
    usageGuideunit,
    ModeTypes,
    handleChangeAnalyticeMode,
    setAnalyticeIsShowAoi,
    getDarkColor,
    isAbsMode,
    handleChangeAbsMode,
    opacityTypes,
    handleChangeAnalysisOpacity,
    AnalyticeModeOpacityType
  }
}