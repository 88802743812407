import React, { useMemo } from 'react';
import Chart from 'components/map/Chart';
import 'css/Chart.scss';
import 'css/Summary.scss';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import Tooltip from '@material-ui/core/Tooltip';
import CopyRight from 'components/map/CopyRight';
import { S3_URL } from "utils/constants";
import { useRecoilValue } from 'recoil';
import { currentLangState } from 'atoms/projectData';

//解析対象範囲のPDFリンク表示を許可するProjectIdを定義(北陸電力用)
const pdfLinkPermitProjectId = [
  'PJ000075',
  'PJ000091',
];

// ツールチップ内でHTMLを書けるようにする
// https://v4.mui.com/components/tooltips/#tooltip
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#fff',
    color: '#333',
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #fff',
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  tabButtonArea: {
    zIndex: "9",
    position: "absolute",
    right : "50px",
    bottom: "0",
  },
  menuButton: {
    borderRadius: "0",
  },
  tabPanelArea: {
    width: "100%",
  },
  tabPanelFlex: {
    position: 'absolute',
    top: '-36px',
    right: '50px',
    zIndex: '9',
  },
  tableCellTh: {
    fontSize: '0.7rem',
    '&:first-child': {
      textAlign: 'left',
      width: '100px',
    },
    fontWeight: "bold",
    lineHeight: '1.4',
    padding: '5px',
  },
  noDataCellTh: {
    color: '#fff',
    backgroundColor: '#000',
  },
  Graph: {
    height: "175px",
  },
  chart__date: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
    justifyContent: "space-evenly",
  },
  chart__date_back: {
    width: "0",
    height: "0",
    borderTop: "7px solid transparent",
    borderRight: "7px solid black",
    borderBottom: "7px solid transparent",
    position: "relative",
    top: "2px",
    right: "-60px",
  },
  chart__date_forward: {
    width: "0",
    height: "0",
    borderLeft: "7px solid black",
    borderTop: "7px solid transparent",
    borderBottom: "7px solid transparent",
    position: "relative",
    top: "2px",
    left: "-60px",
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  paper: {
    padding: "10px",
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  tableCell: {
    padding: '5px',
    fontSize: '0.7rem',
    '&:first-child': {
      textAlign: 'left'
    },
  },
  fontSize12: {
    fontSize: '12px',
  },
  typography: {
    padding: "0 5px",
    textAlign: "left",
    borderBottom: "2px solid",
    fontWeight: 'bold',
    color: "#004ea2",
  },
  aoiTableCell: {
    minWidth: "75px",
    '&:first-child': {
      minWidth: "80px",
    },
  },
  pointTableCell: {
    fontSize: '0.7rem',
    Width: "75px",
    '&:first-child': {
      textAlign: 'left',
      Width: "80px",
    },
    padding: '5px',
  },
  backgroundWhite: {
    background: '#fff',
  },
  rankRecAve: {
    background: '#fff',
  },
  rankRecMax: {
    background: '#fff',
  },
  rankAllMax: {
    background: '#fff',
  },
  rankAllAve: {
    background: '#fff',
  },
  title__summary: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  table__tooltip:{
    borderCollapse: "collapse"
  },
  table__cell:{
    border: "1px solid #000",
    padding: "7px 5px 5px 5px",
  },
  table__rank1:{
    background: '#7e017c',
    color: '#fff',
    fontWeight: "bold",
  },
  table__rank2:{
    background: '#fb00ff',
    color: '#fff',
    fontWeight: "bold",
  },
  table__rank3:{
    background: '#fa0800',
    color: '#fff',
    fontWeight: "bold",
  },
  table__rank4:{
    background: '#fdfd12',
    fontWeight: "bold",
  },
  table__rank5:{
    background: '#0affff',
    fontWeight: "bold",
  },
}));

export default function Summary(props) {
  const {
    t,
    projectId,
    pointDatasState,
    pointRows,
    handleDrawerOpen,
    handleDrawerClose,
    isSummaryOpen,
    graphMaxConfig,
    graphMinConfig,
    aoiClickFlag,
    clickAoiId,
    observationPointData,
    raineyToggleFlag,
    mapStyle,
    analysisVer,
    graphShowHideConfig,
    selectedSelectBoxStartDate,
    selectedSelectBoxEndDate,
    commonMapLayersState,
    analysisPointData
  } = props;

  //Recoilから取得
  const lang = useRecoilValue(currentLangState);

  // YYMMDD形式の文字列をYYYY/MM/DD形式に変換
  const convertToYYYYMMDD = (dateStr) => {
    const year = dateStr.slice(0, 2); // 最初の2文字が年
    const month = dateStr.slice(2, 4); // 3-4文字目が月
    const day = dateStr.slice(4, 6); // 5-6文字目が日
    const fullYear = year >= '50' ? '19' + year : '20' + year; // 西暦を補完
    return `${fullYear}/${month}/${day}`;
  }

  // 解析点ごとの変動ランクの計算
  const calcFluctuationRank = (diffDays, diffValue) => {
    // 月単位に直す
    const movesPerDay = diffValue / diffDays; // 1日あたりの変動
    const movesForTargetDays = Math.abs(movesPerDay * 30); // 30日あたりの変動量(絶対値)   

    switch (true) {
      case movesForTargetDays >= 300:
        return(1); // 変動A’’
      case movesForTargetDays >= 30 && movesForTargetDays < 300:
        return(2); // 変動A’
      case movesForTargetDays >= 10 && movesForTargetDays < 30:
        return(3); // 変動A
      case movesForTargetDays >= 2 && movesForTargetDays < 10:
        return(4); // 変動B
      default:
        return(5); // 変動C
    }
  }

  // 解析点ごとに斜面変動ランクを求める処理
  const rankCalcAnalysisPointData = useMemo(() => {
    if (Object.keys(analysisPointData).length === 0){
      return [];
    }

    let sortedKeys = []; // 日付データを降順にしてここに格納
    if (analysisVer >= 2.0){
      sortedKeys = Object.keys(analysisPointData)
      .filter(key => /^\d{6}$/.test(key)) // 6桁の日付形式のキーをフィルタリング
      .sort((a, b) => b - a); // 降順にソート
    }else{
      sortedKeys = Object.keys(analysisPointData)
      .filter(key => /^\d{4}\/\d{2}\/\d{2}$/.test(key)) // YYYY/MM/DD形式のキーをフィルタリング
      .map(key => {
        const [year, month, day] = key.split('/'); // 年、月、日を分割
        return `${year.slice(2)}${month}${day}`; // YYMMDD形式に変換
      })
      .sort((a, b) => b - a); // 降順にソート
    }

    // 計算に必要なための値を定義
    let latestDate = sortedKeys[0]; // 最新の日付と値  
    let latestValue; // 最新の日付と値 
    let secondLatestDate = sortedKeys[1]; // 最新から2番目の日付と値
    let secondLatestValue; // 最新から2番目の日付と値
    let oldestDate = sortedKeys[sortedKeys.length - 1]; // 一番古い日付と値
    let oldValue; // 一番古い日付と値
    if (analysisVer >= 2.0){
      latestValue = analysisPointData[latestDate];
      secondLatestValue = analysisPointData[secondLatestDate];
      oldValue = analysisPointData[oldestDate];
    }else{
      latestValue = analysisPointData[convertToYYYYMMDD(latestDate)];
      secondLatestValue = analysisPointData[convertToYYYYMMDD(secondLatestDate)];
      oldValue = analysisPointData[convertToYYYYMMDD(oldestDate)];
    }
    // 直近2時期の値の計算
    const twoPeriodsValue = latestValue - secondLatestValue;
    // 全期間の値の計算
    const allPeriodsValue = latestValue - oldValue;

    // 直近2時期の日付の差分を取得
    const formattedDate1 = `20${secondLatestDate.slice(0, 2)}-${secondLatestDate.slice(2, 4)}-${secondLatestDate.slice(4, 6)}`; // 最新から2番目の日付
    const formattedDate2 = `20${latestDate.slice(0, 2)}-${latestDate.slice(2, 4)}-${latestDate.slice(4, 6)}`; // 最新の日付
    const formattedDate3 = `20${oldestDate.slice(0, 2)}-${oldestDate.slice(2, 4)}-${oldestDate.slice(4, 6)}`; // 最古の日付
    const d1 = new Date(formattedDate1);
    const d2 = new Date(formattedDate2);
    const d3 = new Date(formattedDate3);
    const twoPeriodsDiffTime = d2 - d1;
    const twoPeriodsDiffDays = twoPeriodsDiffTime / (1000 * 60 * 60 * 24); // ミリ秒から日数に変換
    const allDiffTime = d2 - d3;
    const allDiffDays = allDiffTime / (1000 * 60 * 60 * 24); // ミリ秒から日数に変換

    //　解析点ごとの変動ランクを算出
    const allPeriodsAverage = calcFluctuationRank(allDiffDays, allPeriodsValue); // 全期間2時期平均
    const twoPeriodsAverage = calcFluctuationRank(twoPeriodsDiffDays, twoPeriodsValue); // 直近2時期平均

    return [
      { [t("map.wholePeriodRank")]: allPeriodsAverage },
      { [t("map.PeriodsRank")]: twoPeriodsAverage }
    ];

  },[analysisPointData, analysisVer])

  // グラフ上部に表示する解析ポイントの情報を作成する
  const pointDataRows = {};
  pointRows.forEach((value, index) => {
    switch(index){
      case 1 : // pid
        pointDataRows['pid'] = value;
        break
      case 2 : // lat
        pointDataRows['lat'] = value;
        break
      case 3 : // lng
        pointDataRows['lng'] = value;
        break
      case 4 : // 変動速度
        pointDataRows['velocity'] = value;
        break
      case 5 : // 標高
      pointDataRows['h'] = value;
        break
      case 6 : // 勾配の向き
      pointDataRows['d'] = value;
        break
      case 7 : // 勾配の角度
        pointDataRows['g'] = value;
        break
      case 8 : // θ
        pointDataRows['s'] = value;
        break
      case 9 : // 信頼度
        pointDataRows['c'] = value;
        break
      default :
        break
    }
  });

  const theme = useTheme();
  const classes = useStyles();

  // 各AOIの詳細PDFを別タブで表示する処理
  const openAoiPdf = () => {
      window.open(`${S3_URL}document/hokuriku/rikuden_${clickAoiId}.pdf`, '_blank');
  }

  return (
    <>
      <div className={classes.tabButtonArea}>
        <Button
          color="primary"
          variant="contained"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          style={{borderRadius: "0"}}
          className={clsx(classes.menuButton, isSummaryOpen && classes.hide)}
        >
          <ExpandLessIcon />
        </Button>
      </div>
      <Drawer
        style={{justifyContent: "flex-end"}}
        variant="persistent"
        anchor="bottom"
        open={isSummaryOpen}
      >
        <div className={classes.tabPanelArea}>
          {/* 著作権表記 */}
          <CopyRight
            mapStyle={mapStyle}
            isSummaryOpen={isSummaryOpen}
            commonMapLayersState={commonMapLayersState}
          ></CopyRight>
          {/* ドロワーメニューの開閉ボタン */}
          <div className={classes.tabPanelFlex}>
            <Button
              style={{borderRadius: "0"}}
              onClick={handleDrawerClose}
              color="primary"
              variant="contained"
            >
              {theme.direction === 'ltr' ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </Button>
          </div>
          {/* ドロワーメニュー */}
          <div className={classes.backgroundWhite}>
            <div className={classes.root}>
              <Grid container spacing={1}>
                <div className='summaryUnderArea'>
                  {lang === "ja" && 
                    <div className='summaryUnderAreaItem'>
                      <Grid item xs={12} style={{marginLeft: "10px"}}>
                        <Typography className={classes.typography} variant="subtitle1" gutterBottom>
                          {t("map.amountPoints")}
                          <HtmlTooltip
                          style={{}}
                          classes={{
                            tooltip: classes.customTooltip,
                            arrow: classes.customArrow
                          }}
                          placement="top"
                          arrow
                          title={
                            <>
                              <table className={classes.table__tooltip}>
                              <tr>
                                <th className={classes.table__cell}>{t("map.slopeVariationRank")}</th>
                                <th className={classes.table__cell}>{t("map.variationStatus")}</th>
                                <th className={classes.table__cell}>{t("map.averageMonthlyVariation")}</th>
                              </tr>
                              <tr>
                                <td className={`${classes.table__cell} ${classes.table__rank1}`}>{t("map.variationSymbolA´´")}</td>
                                <td className={classes.table__cell}>{t("map.variationTextA´´")}</td>
                                <td className={classes.table__cell}>300mm/{t("map.moreThanMonth")}</td>
                              </tr>
                              <tr>
                                <td className={`${classes.table__cell} ${classes.table__rank2}`}>{t("map.variationSymbolA´")}</td>
                                <td className={classes.table__cell}>{t("map.variationTextA´")}</td>
                                <td className={classes.table__cell}>30mm/{t("map.moreThanMonth")} 300mm{t("map.lessThanMonth")}</td>
                              </tr>
                              <tr>
                                <td className={`${classes.table__cell} ${classes.table__rank3}`}>{t("map.variationSymbolA")}</td>
                                <td className={classes.table__cell}>{t("map.variationTextA")}</td>
                                <td className={classes.table__cell}>10mm/{t("map.moreThanMonth")} 30mm/{t("map.lessThanMonth")}</td>
                              </tr>
                              <tr>
                                <td className={`${classes.table__cell} ${classes.table__rank4}`}>{t("map.variationSymbolB")}</td>
                                <td className={classes.table__cell}>{t("map.variationTextB")}</td>
                                <td className={classes.table__cell}>2mm/{t("map.moreThanMonth")} 10mm/{t("map.lessThanMonth")}</td>
                              </tr>
                              <tr>
                                <td className={`${classes.table__cell} ${classes.table__rank5}`}>{t("map.variationSymbolC")}</td>
                                <td className={classes.table__cell}>{t("map.variationTextC")}</td>
                                <td className={classes.table__cell}>2mm/{t("map.lessThanMonth")}</td>
                              </tr>
                              </table>
                            </>
                          }
                        >
                          <Button className={classes.helpButton}>
                            <HelpTwoToneIcon className={classes.HelpTwoToneIcon} />
                          </Button>
                        </HtmlTooltip>
                        </Typography>
                      </Grid>
                      {/* 解析点ごとの斜面変動ランクを表示 */}
                      <div>
                        {rankCalcAnalysisPointData.length > 0 &&
                          rankCalcAnalysisPointData.map((obj, index) => (
                            <div style={{marginLeft: "10px"}} key={index}>
                              {Object.entries(obj).map(([key, value]) => (
                                <div key={key} style={{display: "flex", alignItems: "center", textAlign: "left", marginTop: "20px", fontSize: "12px", fontWeight: "bold"}}>
                                  <div>{key}:</div>
                                  <div
                                    style={{
                                      padding: "3px 3px 2px 3px",
                                      marginLeft: "10px",
                                      color: (() => {
                                        if (value === 1 || value === 2) return "#fff";
                                        return "#000"; // デフォルト値を指定
                                      })(),
                                      backgroundColor: (() => {
                                        if (value === 1) return "#7e017c";
                                        if (value === 2) return "#fb00ff";
                                        if (value === 3) return "#fa0800";
                                        if (value === 4) return "#fdfd12";
                                        if (value === 5) return "#0affff";
                                        return "none"; // デフォルト値を指定
                                      })()
                                    }}
                                  >
                                    {(() => {
                                      if (value === 1) return "変動 A’’(警戒変動)";
                                      if (value === 2) return "変動 A’(注意変動)";
                                      if (value === 3) return "変動 A(確定変動)";
                                      if (value === 4) return "変動 B(準確定変動)";
                                      if (value === 5) return "変動 C(潜在変動)以下";
                                      return "データなし"; // デフォルトの表示
                                    })()}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ))
                        }
                      </div>

                      {/* 北陸電力用のPDFを表示 */}
                      { pdfLinkPermitProjectId.includes(projectId) && aoiClickFlag === true &&
                        <div className='aoiPdfLink'>  
                          <div>
                            <span
                              onClick={openAoiPdf}
                            >
                              参考：本解析対象範囲の二時期差分解析結果
                            </span>
                          </div>
                        </div>
                      }
                    </div>
                  }
                  <div className='summaryUnderAreaItem'>
                    <Grid item xs={12} style={{marginLeft: "20px", marginTop: "8px"}}>
                      <Typography className={classes.typography} variant="subtitle1" gutterBottom>
                        {t("map.amountPointsGraph")}
                      </Typography>
                    </Grid>
                    <div>
                      <div>
                        {Object.keys(pointDataRows).length
                        ?
                        <div className='pointDataArea'>
                          <div className='pointData'>
                            <div className='pointDataItem'>・PID : <span>{pointDataRows.pid}</span></div>
                            <div className='pointDataItem'>・{t("map.rateOfVariation")}（mm/{t("map.year")}）: <span>{pointDataRows.velocity}</span></div>
                            <div className='pointDataItem'>・{t("map.Latitude")} : <span>{pointDataRows.lat}</span></div>
                            <div className='pointDataItem'>・{t("map.Longitude")} : <span>{pointDataRows.lng}</span></div>
                          </div>
                          {analysisVer >= 2.0 && (
                            <div className='pointData'>
                              <div className='pointDataItem'>・{t("map.elevation")} : <span>{pointDataRows.h}</span></div>
                              <div className='pointDataItem'>・{t("map.directionOfSlope")} : <span>{pointDataRows.d}</span></div>
                              <div className='pointDataItem'>・{t("map.slopeDirectonSize")} : <span>{pointDataRows.g}</span></div>
                              {pointDataRows.c && <div className='pointDataItem'>・*{t("map.reliability")} : <span>{pointDataRows.c}</span></div>}
                            </div>
                          )}
                        </div>
                        :
                          <div className='pointData'></div>
                        }
                        <div className={classes.Graph}>
                          {pointRows.length > 0 && (
                            <section className={classes.chart__date}>
                              <span style={{height: "14px"}} className={classes.fontSize12}></span>
                            </section>
                          )}
                          {/* グラフエリア */}
                          {pointRows.length > 0 && (
                            <Chart
                              t={t}
                              pointDatasState={pointDatasState}
                              graphMaxConfig={graphMaxConfig}
                              graphMinConfig={graphMinConfig}
                              observationPointData={observationPointData}
                              raineyToggleFlag={raineyToggleFlag}
                              graphShowHideConfig={graphShowHideConfig}
                              selectedSelectBoxStartDate={selectedSelectBoxStartDate}
                              selectedSelectBoxEndDate={selectedSelectBoxEndDate}
                            ></Chart>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}
